import { Easing } from "@tweenjs/tween.js";
import { useDebounce } from "hooks/utils";
import { useBreakpoints } from "hooks/utils/useBreakpoints";
import { useCallback, useEffect, useMemo, useRef } from "react"
import * as THREE from 'three';
import { Intersection, Object3D } from "three";
import { ElementOrbitParams, useElementOrbit } from "./elements/useElementOrbit";
import { use3DAnimation } from "./use3DAnimation";
import { use3DElements } from "./use3DElements";
import { useThreeJsAnimations } from "./useThreeJsAnimations";
import { INITIAL_CAMERA_DISTANCE, MAX_DISTANCE, MAX_DISTANCE_SM } from "./useThreeJsControls";

export const useThreeJsInit = ({ removeImages, ...props }: ThreeJsInitParams) => {

    const isInit = useRef(false)
    const scene = useMemo(() => new THREE.Scene(), [])
    const { xs, sm } = useBreakpoints()

    const { translateUp } = useThreeJsAnimations()

    const mouseDownTime = useRef<number>()
    const debounceResize = useDebounce()
    const cameraMaxDistance = useMemo(() => sm ? MAX_DISTANCE_SM : MAX_DISTANCE, [sm])

    const camera = useMemo(() => {
        const camera = new THREE.PerspectiveCamera(60, window.outerWidth / window.outerHeight, 0.1, 1000)
        camera.updateProjectionMatrix();
        camera.position.z = removeImages ? cameraMaxDistance : INITIAL_CAMERA_DISTANCE
        return camera
    }, [removeImages])


    const renderer = useMemo(() => {
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setClearColor(0x000000, 0)
        renderer.setSize(window.outerWidth, window.outerHeight);
        return renderer
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        window.addEventListener('mousedown', handleMouseDown)
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mouseup', handleMouseUp)
            window.removeEventListener('mousedown', handleMouseDown)
        }
    }, [props])

    const handleResize = useCallback(() => {
        debounceResize(() => {
            camera.aspect = window.outerWidth / window.outerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.outerWidth, window.outerHeight);
        }, 500)
    }, [renderer])

    const { elements, animations } = use3DElements(removeImages)
    const { orbitFn, animations: _animations } = useElementOrbit(props)

    const {
        animate,
        controls,
        intersects
    } = use3DAnimation({
        camera,
        scene,
        renderer,
        animations: [...animations, ..._animations]
    })

    const handleMouseDown = () => {
        mouseDownTime.current = Date.now()
    }

    const handleMouseUp = (e: any) => {
        if (e.target.tagName !== 'CANVAS') {
            return;
        }
        const diff = Date.now() - mouseDownTime.current!
        if (diff < 100) {
            props.onClick?.(intersects.objects)
        }
    }

    const cameraAnimation = useMemo(() => {
        return translateUp({
            position: camera.position,
            targetUp: { z: cameraMaxDistance },
            targetDown: {},
            duration: 2000,
            behavior: Easing.Linear.None
        }).onComplete(() => controls.maxDistance = cameraMaxDistance)
    }, [camera])

    const initRenderer = useCallback(async (container: HTMLDivElement) => {

        if (isInit.current) {
            return;
        }
        isInit.current = true

        requestAnimationFrame(animate);
        if (!removeImages) {
            const element = await orbitFn();
            scene.add(element)
        }

        elements.forEach((item: any) => scene.add(item))
        renderer.setAnimationLoop(animate);
        container.appendChild(renderer.domElement)
    }, [])

    useEffect(() => {
        return () => {
            renderer.dispose()
        }
    }, [])

    return {
        threeJsInit: initRenderer,
        cameraAnimation
    }
}

export type ThreeJsInitParams = ElementOrbitParams & {
    removeImages?: boolean
    onClick?: (intersets: Array<Intersection<Object3D>>) => void
}
