import { AppRouteProps } from "components/AppRoutes";
import Shop from "./containers/Shop";


export const ShopRoutes: Array<AppRouteProps> = [
    // {
    //     path: '/shop',
    //     title: `App.Shop.Title`,
    //     element: <Shop />
    // },
]