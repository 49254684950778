import { useTranslation } from 'react-i18next';
import { useTranslations } from "hooks/translation";
import { useMemo } from "react";
import { useTranslationRoute } from 'hooks/translation/useTranslationRoute';
export const useMainMenus = () => {
    const [
        ALBUM_LABEL,
        CARDS_LABEL,
        CLIPS_LABEL,
        CONCERT_LABEL,
        PICTURE_LABEL,
        SHOP_LABEL,
    ] = useTranslations(i18nKeys);

    const { i18n } = useTranslation();
    const url = useTranslationRoute()

    return useMemo(
        () => [
            {
                label: ALBUM_LABEL,
                link: url("albums"),
            },
            {
                label: CARDS_LABEL,
                link: url("cartes"),
            },
            {
                label: CLIPS_LABEL,
                link: url("videos"),
            },
            {
                label: CONCERT_LABEL,
                link: url("concerts",)
            },
            {
                label: PICTURE_LABEL,
                link: url("pictures"),
            },
            {
                label: SHOP_LABEL,
                link: "#",
                disabled: true
            },
        ],
        [i18n.language]
    );
};

const i18nKeys = [
    "App.Menu.Albums.Label",
    "App.Menu.Cards.Label",
    "App.Menu.Clips.Label",
    "App.Menu.Concerts.Label",
    "App.Menu.Pictures.Label",
    "App.Menu.Shop.Label",
];
