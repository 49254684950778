import CardItem from "components/CardItem"
import { useTranslations } from "hooks/translation"
import { useBreakpoints } from "hooks/utils/useBreakpoints";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { FreeMode } from "swiper";

const Concerts = () => {
    const [TITLE, CONTENT, LOVE, HINTS, SEE_DATES] = useTranslations(i18nKeys)
    const { xs, sm, md } = useBreakpoints();

    return (
        <section className="flex flex-col flex-1 gap-8 sm:gap-4 md:gap-6 justify-center items-center w-full">
            {
                !md &&
                <div className="grid grid-rows-[repeat(3,240px)] sm:grid-rows-none sm:grid-cols-[190px_240px_190px] lg:grid-cols-3 gap-4 sm:gap-8">
                    <div className="w-full flex justify-center max-w-[280px]">
                        <img className="h-full object-cover" src="/assets/img/jain-the-fool-tour.jpg" />
                    </div>
                    <CardItem bigCard>
                        <div className="flex flex-col gap-2 md:gap-6 items-center text-secondary text-center py-[15px] px-[40px] max-w-[240px]">
                            <h3 className="animate__animated animate__fadeIn text-[18px] md:text-[34px]" style={{ animationDelay: '100ms' }}>{TITLE}</h3>
                            <hr className="h-[1px] w-[150px] bg-secondary" />
                            <p className="animate__animated animate__fadeIn text-[12px] md:text-[18px]" style={{ animationDelay: '300ms' }}>
                                {CONTENT}
                            </p>
                        </div>
                    </CardItem>
                    <div className="w-full flex justify-center max-w-[280px]">
                        <img className="h-full object-cover" src="/assets/img/jain-the-fool-tour-back.jpg" />
                    </div>
                </div>
            }

            {
                md &&
                <Swiper
                    className="w-full h-full sm:h-[240px] lg:h-full"
                    modules={[FreeMode]}
                    breakpoints={{
                        320: {
                            slidesPerView: 1.2,
                        },
                        768: {
                            slidesPerView: 3.2,
                        },
                    }}
                    freeMode
                >
                    <SwiperSlide className="h-full flex justify-center">
                        <div className="w-full flex justify-center max-w-[280px]">
                            <img className="h-full object-cover" src="/assets/img/jain-the-fool-tour.jpg" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="h-full flex justify-center">
                        <CardItem bigCard className="h-full">
                            <div className="flex flex-col gap-2 md:gap-6 items-center text-secondary text-center py-[60px] px-[40px] max-w-[240px]">
                                <h3 className="animate__animated animate__fadeIn " style={{ animationDelay: '100ms' }}>{TITLE}</h3>
                                <hr className="h-[1px] w-[150px] bg-secondary" />
                                <p className="animate__animated animate__fadeIn " style={{ animationDelay: '300ms' }}>
                                    {CONTENT}
                                </p>
                            </div>
                        </CardItem>
                    </SwiperSlide>
                    <SwiperSlide className="h-full flex justify-center">
                        <div className="w-full flex justify-center max-w-[280px]">
                            <img className="h-full object-cover" src="/assets/img/jain-the-fool-tour-back.jpg" />
                        </div>
                    </SwiperSlide>
                </Swiper>
            }

            <div className="lg:mt-[50px] sm:mt-2 mt-[10px]">
                <a href="https://tickets.jain-music.com" target="_blank">
                    <button className="btn-contained animated-button animate__animated animate__flipInX" style={{ animationDelay: '500ms' }}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <p className="font-bold text-[16px] md:text-[22px]">{SEE_DATES}</p>
                    </button>
                </a>
            </div>
        </section>
    )
}

const i18nKeys = [
    "App.Concerts.Cards.DayPrediction",
    "App.Concerts.Cards.Content",
    "App.Concerts.Cards.Love",
    "App.Concerts.Cards.Hints",
    "App.Concerts.SeeDates"
]

export default Concerts
