import FirstExperience from "components/FirstExperience"
import { useIsHome } from "hooks/utils/useIsHome"
import { createRef, useCallback, useEffect, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { FirstNavigateHomeState } from "state/FirstHomeState"
import { Intersection, Object3D } from "three"
import { ThreeDTips } from "../components/ThreeDTips"
import { ThreeJsInitParams, useThreeJsInit } from "../hooks/useThreeJsInit"

const ThreeD = (props: ThreeDProps) => {
    const containerRef = createRef<HTMLDivElement>()
    const [loading, setIsloading] = useState(true)

    const isHome = useIsHome();
    const [showFirstXp, setShowFirstXp] = useState(true)
    const hasNavigateHomeFirst = useRecoilValue(FirstNavigateHomeState)
    const showTipsTimeout = useRef<NodeJS.Timeout>()
    const [showTips, setShowTips] = useState<boolean>()
    const tipsTimeout = useRef(2000)

    const handleElementClick = (intersets: Array<Intersection<Object3D>>) => {
        if (intersets.some((item) => item.object.name === 'the-fool')) {
            window.open('https://jain.lnk.to/thefoolwebsite', '_blank')
        }
    }

    const { threeJsInit, cameraAnimation } = useThreeJsInit({
        ...props,
        onClick: handleElementClick
    })

    useEffect(() => {
        init()
        if (props.animateCameraOnStart || !hasNavigateHomeFirst) {
            cameraAnimation.start()
        }
        window.addEventListener('mousemove', handleMouseMove)
        window.addEventListener('click', handleClick)
        window.addEventListener('touchstart', handleClick)
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
            window.removeEventListener('click', handleClick)
            window.removeEventListener('touchstart', handleClick)
            clearTimeout(showTipsTimeout.current)
        }
    }, [])

    const init = useCallback(async () => {
        if (loading) {
            await threeJsInit(containerRef.current!)
            setIsloading(false)
        }
    }, [])

    const handleMouseMove = useCallback(() => {
        if (hasNavigateHomeFirst && !showFirstXp && showTips === undefined) {
            countTipsTimeout()
        }
    }, [])

    const handleClick = useCallback(() => {
        clearTimeout(showTipsTimeout.current)
        setShowTips(false)
    }, [])

    const countTipsTimeout = useCallback(() => {
        clearTimeout(showTipsTimeout.current)
        showTipsTimeout.current = setTimeout(() => {
            setShowTips(true)
        }, tipsTimeout.current)
    }, [])

    const handleOverlayFadding = () => {
        if (!loading) {
            cameraAnimation.start()
            countTipsTimeout()
        }
    }

    const handleTipsClick = (e: any) => {
        e.preventDefault()
        setShowFirstXp(false)
        setShowTips(false)
    }
    
    return (

        <div>

            {
                (isHome && hasNavigateHomeFirst && showFirstXp) &&
                <FirstExperience isLoading={loading} onFading={handleOverlayFadding} />
            }

            {
                (isHome && hasNavigateHomeFirst && showTips) &&
                <ThreeDTips
                    onMouseDown={handleTipsClick}
                    onTouchStart={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                />
            }
            <div
                key="containerRef"
                id={props.id}
                className="absolute z-0 inset-0 left-[-35px] right-[-35px] flex justify-center items-center  animate__animated animate__fadeIn"
                ref={containerRef}>

            </div>
        </div>
    )
}

type ThreeDProps = ThreeJsInitParams & {
    id?: string
    animateCameraOnStart?: boolean
}

export default ThreeD
