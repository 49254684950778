import { TCard } from "../hooks/useCardList";
import { useVideoLoader } from "../hooks/useVideoLoader";

const CardItem = (props: TCard) => {
    
    const {isLoading, videoRef} = useVideoLoader()

    return (
        <div className="card-background-container relative md:h-[396px] h-[300px]">
            {
                isLoading && <img src={props.srcImg!} width="230px" className="object-contain md:max-h-full max-h-[300px]" alt={props.title}/>
            }
            <video autoPlay muted loop ref={videoRef} src={props.srcVideo!} width="230px" className="md:max-h-full max-h-[300px] animate__animated animate__fadeIn"/>
        </div>
    )
}

export default CardItem;
